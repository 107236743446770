<!-- 客服 -->
<template>
  <div id="service">
    <!-- 顶部导航 -->
    <!-- <van-nav-bar
      :title="$t('main.zxkf')"
      left-arrow
      @click-left="onClickLeft"
    /> -->
		<!-- <view class="header"></view> -->
    <iframe
      v-if="show"
      :src="url1"
      height="100%"
      width="100%"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getapi } from "@/api/investhome";
export default {
  name: "",
  computed: { ...mapState(["hasLogin", "user"]) },
  data() {
    return {
      show: false,
      url1: "",
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
		
	},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    // this.url1 = `http://kf.stexj.cn/index/index/home?visiter_id=${this.user.id}&visiter_name=${this.user.username}&avatar=&business_id=1&groupid=0&special=1`;
    // this.url1 = `https://9cdda7217c5dd.chatnow.mstatik.com/widget/standalone.html?eid=8c7f880d039f683e21af1ed541d7b13b`;
    this.url1 = ``;
    // this.url1 = `http://kf.cqjgny.cn/index/index/home?visiter_id=${this.user.id}&visiter_name=${this.user.username}&avatar=&business_id=1&groupid=0&special=1`;
    console.log(this.url1);
    this.show = true;
		this.loadData();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
		loadData() {
			getapi().then((res) => {
			  console.log(res);
			  if (res.code + "" === "200") {
					this.url1 = res.data.url
					// console.log(this.url1)
				}
			});
		}
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/mobile.less";
.van-nav-bar {
  position: fixed;
  width: 100%;
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}
.header{
	width: 100%;
	margin: 20rpx 0;
	// height: 100rpx;
}
/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}
#service {
  width: 100%;
  height: 100%;
}
iframe{
	margin-top: 40rpx;
}
/* @import url(); 引入css类 */
</style>